<template>
  <div class="campaign_insight_container customize_theme">
    <el-card
      :body-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }"
      style="margin-bottom: 20px; border-radius: 10px; color: #11263c"
    >
      <div style="display: flex; font-size: 16px">
        <div>
          选择时间
          <el-select
            v-model="duration"
            placeholder="请选择"
            size="mini"
            style="margin-left: 20px; width: 102px"
          >
            <el-option
              v-for="item in durationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-left: 40px">
          查找产品/品牌
          <el-input
            style="width: 160px; margin-left: 20px"
            size="mini"
            placeholder="请输入品牌名称"
            v-model="searchWord"
            @change="searchWordChange"
            clearable
          ></el-input>
        </div>
      </div>
      <el-button
        type="primary"
        size="mini"
        @click="addBrand"
        style="height: 28px; padding: 0 8px"
        >新增品牌<i
          class="el-icon-plus"
          style="margin-left: 5px; vertical-align: center !important"
        ></i
      ></el-button>
    </el-card>
    <el-card style="border-radius: 10px">
      <div style="display: flex; justify-content: space-between">
        <el-button
          size="mini"
          style="width: 92px"
          @click="showFilter = !showFilter"
          :class="showFilter ? 'active_button' : ''"
        >
          <svg-icon
            class-name="filter"
            class="icon_hover_fill"
            style="margin-right: 6px"
          />
          筛选器
        </el-button>
        <div style="display: flex; align-items: center">
          <el-button
            type="primary"
            size="mini"
            :disabled="selection.length < 2"
            @click="jumpToRoute('InsightCompare')"
            style="width: 61px"
          >
            对比
          </el-button>
          <el-button
            type="primary"
            size="mini"
            :disabled="!selection.length"
            @click="jumpToRoute('InsightCalendar')"
            style="width: 61px"
          >
            日历
          </el-button>
          <el-button
            size="mini"
            @click="handleExpansion"
            style="width: 92px"
            :class="
              expansion.length && expansion.length === tableData.length
                ? 'active_button'
                : ''
            "
          >
            {{
              expansion.length && expansion.length === tableData.length
                ? "ROI收起"
                : "ROI展开"
            }}
            <i
              :class="
                expansion.length && expansion.length === tableData.length
                  ? 'el-icon-arrow-up'
                  : 'el-icon-arrow-down'
              "
            ></i>
          </el-button>
          <el-select
            v-model="ROISort"
            placeholder="请选择ROI排序"
            size="mini"
            style="margin-left: 10px; width: 116px"
            @change="handleROISort"
            clearable
          >
            <el-option
              v-for="item in ROISortOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        v-show="showFilter"
        style="
          margin-top: 5px;
          padding: 10px 0;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #eee;
          border-top: 1px solid #eee;
        "
      >
        <div>
          <el-input-number
            :controls="false"
            placeholder="费用区间"
            size="mini"
            v-model="floorPrice"
            :min="0"
            :max="ceilingPrice"
            style="width: 120px"
          ></el-input-number>
          -
          <el-input-number
            :controls="false"
            placeholder="费用区间"
            size="mini"
            v-model="ceilingPrice"
            :min="floorPrice ? floorPrice : 0"
            style="width: 120px; margin-right: 20px"
          ></el-input-number>
        </div>
        <el-select
          v-model="group"
          placeholder="请选择集团"
          size="mini"
          clearable
          style="margin-right: 20px; width: 116px"
        >
          <el-option
            v-for="item in groupOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-cascader
          v-model="category"
          :options="categoryOptions"
          placeholder="请选择品类"
          size="mini"
          clearable
          :props="{ label: 'tagName', value: 'tagId', emitPath: false }"
          style="margin-right: 20px; width: 116px"
        ></el-cascader>
        <el-button type="primary" size="mini" @click="resetToGetList"
          >确定
        </el-button>
        <el-button size="mini" @click="resetFilterParams">重置</el-button>
      </div>
      <el-table
        class="theme_table"
        :data="tableData"
        ref="multipleTable"
        stripe
        :default-sort="{ prop: 'startDate', order: 'descending' }"
        @select-all="selectAll"
        @select="selectChange"
        @expand-change="expandChange"
        @sort-change="handleSort"
        :header-cell-style="{ color: '#8C98A5' }"
        :cell-style="{ color: '#34385b' }"
      >
        <el-table-column type="expand">
          <template #default="{ row }">
            <div style="display: flex; padding-left: 42px">
              <div style="display: flex; flex: 1">
                <div
                  style="border-right: 1px solid #e7e7e7; padding-right: 20px"
                >
                  <p style="margin: 5px 0 20px">{{ row.groupName }}</p>
                  <p>{{ row.brandName }}</p>
                </div>
                <div style="width: 500px; display: flex; flex-wrap: wrap">
                  <span
                    style="margin: 5px 0 5px 20px"
                    v-for="item in row.product"
                    :key="item"
                    >{{ item }}</span
                  >
                </div>
              </div>
              <div style="width: 500px">
                <span style="margin-left: 40px">
                  <el-tooltip
                    effect="dark"
                    content="单个UV触达成本"
                    placement="top"
                  >
                    <svg-icon class-name="insight_question" />
                  </el-tooltip>
                  CPI {{ row.cpi ? row.cpi : "N/A" }}</span
                >
                <span style="margin-left: 40px">
                  <el-tooltip
                    effect="dark"
                    content="单个互动成本"
                    placement="top"
                  >
                    <svg-icon class-name="insight_question" />
                  </el-tooltip>
                  CPE {{ row.cpe ? row.cpe : "N/A" }}</span
                >
                <span style="margin-left: 40px">
                  <el-tooltip
                    effect="dark"
                    content="互动数 / 曝光数"
                    placement="top"
                  >
                    <svg-icon class-name="insight_question" />
                  </el-tooltip>
                  ER
                  {{ row.engagementRate ? row.engagementRate : "N/A" }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          v-for="item in colData"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :width="item.width"
          :sortable="item.sortable"
          :formatter="item.formatter"
        >
        </el-table-column>
      </el-table>
      <div class="insight_pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalSize"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { h } from 'vue';
import {
  getGroup,
  getCateTree,
  getCampaignInsightList,
} from "@/api/campaignInsight";

export default {
  name: "index",
  data() {
    return {
      duration: "1mon",
      durationOptions: [
        { label: "近1个月", value: "1mon" },
        { label: "近3个月", value: "3mon" },
        { label: "近6个月", value: "6mon" },
        { label: "近12个月", value: "12mon" },
        { label: "去年", value: "lastYear" },
        { label: "今年", value: "thisYear" },
      ],
      searchWord: "",
      ROISort: "",
      ROISortOptions: [
        { label: "CPI升序", value: "cpi,asc" },
        { label: "CPI降序", value: "cpi,desc" },
        { label: "CPE升序", value: "cpe,asc" },
        { label: "CPE降序", value: "cpe,desc" },
        { label: "ER升序", value: "engagementRate,asc" },
        { label: "ER降序", value: "engagementRate,desc" },
      ],
      showFilter: false,
      floorPrice: undefined,
      ceilingPrice: undefined,
      group: "",
      groupOptions: [],
      category: "",
      categoryOptions: [],
      tableData: [],
      colData: [
        {
          label: "活动",
          prop: "campaignName",
          sortable: false,
          width: 300,
          align: "none",
          formatter: (row, column, cellValue) => {
            const res = h('span', {
              style: 'color: rgb(81, 166, 240); cursor: pointer;',
              onClick: () => this.jumpToDetail(row.id)
            }, cellValue);

            return res;
            // const res = (
            //   <span
            //     style="color: rgb(81, 166, 240);cursor: pointer;"
            //     onClick={() => this.jumpToDetail(row.id)}
            //   >
            //     {cellValue}
            //   </span>
            // );
            // return res;
          },
        },
        {
          label: "发起时间",
          prop: "startDate",
          sortable: "custom",
          width: null,
          align: "center",
        },
        {
          label: "费用",
          prop: "cost",
          sortable: "custom",
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue / 100) : "--";
          },
        },
        {
          label: "曝光",
          prop: "exposure",
          sortable: "custom",
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue) : "--";
          },
        },
        {
          label: "互动",
          prop: "engagement",
          sortable: "custom",
          width: null,
          align: "center",
          formatter: (row, column, cellValue) => {
            return cellValue ? this.sliceDecimal(cellValue) : "--";
          },
        },
      ],
      selection: [],
      expansion: [],
      sortColumn: "startDate",
      sortDirection: "desc",
      currentPage: 1,
      pageSize: 10,
      totalSize: 100,
      limit: 3,
    };
  },
  computed: {
    watchParams() {
      return {
        duration: this.duration,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection ? this.sortDirection : "desc",
      };
    },
    params() {
      const filterParams = {
        floorPrice: this.floorPrice ? this.floorPrice * 100 : "",
        ceilingPrice: this.ceilingPrice ? this.ceilingPrice * 100 : "",
        group: this.group,
        category: this.category ? this.category : "",
        // 不用监控
        searchWord: this.searchWord,
        page: this.currentPage,
        size: this.pageSize,
      };
      return Object.assign(filterParams, this.watchParams);
    },
  },
  mounted() {
    this.getGroup();
    this.getCateTree();
    this.getCampaignInsight();
  },
  methods: {
    async getGroup() {
      const { data } = await getGroup();
      this.groupOptions = data;
    },
    async getCateTree() {
      const { data } = await getCateTree();
      this.categoryOptions = data;
    },
    async getCampaignInsight() {
      const {
        data: { campaigns, total },
      } = await getCampaignInsightList(this.params);
      this.totalSize = total;
      this.expansion = [];
      this.findSelectionInList(campaigns);
    },
    findSelectionInList(list) {
      // 判断选择的行在返回的list中是否存在
      const tempArr = [];
      const selection = [];
      this.selection.forEach((select) => {
        const res = list.find((item) => item.id === select.id);
        // 如果在返回的list中有选中的某行，则将该行的数据从list中放进新selection中
        // 如果没有，则将该行放进拼接数组和新selection中，以保证新selection长度和旧selection一致
        if (res) {
          selection.push(res);
        } else {
          tempArr.push(select);
          selection.push(select);
        }
      });
      this.tableData = tempArr.concat(list);
      setTimeout(() => {
        selection.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        });
      }, 20);
    },
    searchWordChange(val) {
      this.searchWord = val.trim();
      this.resetToGetList();
    },
    jumpToRoute(name) {
      const selection = this.selection.map((item) => this.btoa(item.id));
      this.$router.push({ name, query: { selection } });
    },
    jumpToDetail(rowId) {
      const query = { id: this.btoa(rowId) };
      this.$router.push({ name: "InsightDetail", query });
    },
    selectChange(selection, row) {
      if (selection.length > this.limit) {
        this.$refs.multipleTable.toggleRowSelection(row);
        this.selection = selection.slice(0, this.limit);
        this.$alert(`最多选择${this.limit}项`, "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.selection = selection;
      }
    },
    selectAll(selection) {
      this.$refs.multipleTable.clearSelection();
      if (this.selection.length === this.limit) {
        this.selection = [];
      } else {
        this.selection = selection.slice(0, this.limit);
        this.selection.forEach((item) => {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        });
      }
    },
    expandChange(row, expansion) {
      this.expansion = expansion;
    },
    handleExpansion() {
      const expanded = this.expansion.length === this.tableData.length;
      this.tableData.forEach((item) =>
        this.$refs.multipleTable.toggleRowExpansion(item, !expanded)
      );
    },
    handleSort({ column, prop, order }) {
      if (column && prop && order) {
        this.ROISort = "";
        this.sortColumn = prop;
        this.sortDirection = order ? order.slice(0, -6) : "";
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getCampaignInsight();
    },
    addBrand() {
      this.$alert(
        "如果您正在关注的品牌暂时不在榜单内，请联系邮件地址：omnivoice@meritco-group.com以添加您需要新增的品牌。",
        "提示",
        {
          confirmButtonText: "确定",
        }
      );
    },
    handleROISort(value) {
      if (value) {
        value = value.split(",");
        this.sortColumn = value[0];
        this.sortDirection = value[1];
        this.$refs.multipleTable.clearSort();
      } else {
        this.sortColumn = "startDate";
        this.sortDirection = "desc";
        this.$refs.multipleTable.sort({
          prop: "startDate",
          order: "descending",
        });
      }
    },
    resetToGetList() {
      this.currentPage = 1;
      this.selection = [];
      this.getCampaignInsight();
    },
    resetFilterParams() {
      this.floorPrice = undefined;
      this.ceilingPrice = undefined;
      this.group = "";
      this.category = "";
      this.resetToGetList();
    },
  },
  watch: {
    watchParams() {
      this.resetToGetList();
    },
  },
};
</script>

<style lang="scss">
.campaign_insight_container {
  flex: 1;

  .insight_pagination {
    text-align: right;
    margin-top: 20px;
  }
}
</style>

<style scoped lang="scss">
.active_button {
  color: #fff;
  border-color: #ddd;
  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);

  .icon_hover_fill {
    fill: #fff;
  }
}
</style>
